import './App.css';
import Home from './Paginas/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Logros from './Paginas/Logros';
import Voluntariado from './Paginas/Voluntariado';
import Cv from './Paginas/Cv';
import Valores from './Paginas/Valores';
import Nada from './Paginas/Nada';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/logros/y/reconocimientos' element={<Logros/>}></Route>
          <Route path='/voluntariado' element={<Voluntariado/>}></Route>
          <Route path='/cv' element={<Cv/>}/>
          <Route path='/valores/humanos' element={<Valores/>}/>
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
