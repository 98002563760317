import React from 'react'
import './Cv.css'
import portafolio1 from '../Images/Portafolio1.jpeg'
import portafolio2 from '../Images/Portafolio2.jpeg'
import BotonFijo from '../components/BotonFijo/BotonFijo'

const Cv = () => {
  return (
    <div className='cv'>
              

        <img src={portafolio1} alt="" />
        <img src={portafolio2} alt="" />
        <BotonFijo/>
    </div>
  )
}

export default Cv