import React from 'react'
import './Valores.css'
import valor1 from '../Images/Valor1.jpeg'
import valor2 from '../Images/Valor2.jpeg'
import valor3 from '../Images/Valor3.jpeg'
import BotonFijo from '../components/BotonFijo/BotonFijo'


const Valores = () => {
    return (
        <div className='valores'>
                <img src={valor1} alt="" />
                <img src={valor2} alt="" />
                <img src={valor3} alt="" />
                <BotonFijo/>
        </div>
    )
}

export default Valores